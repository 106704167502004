import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import moment from "moment";
import parse from "html-react-parser";
function LatestNews() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [NewData, setNewData] = useState([]);
  const GetNews = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getnewsandevent`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteNews = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news item?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }

    try {
      const res = await axios.delete(
        `https://thekitu.com/api/admin/deletenewsandevent/${id}`
      );

      if (res.status === 200) {
        alert(res.data.success);
        GetNews(); // Refresh the news list after successful deletion
      }
    } catch (error) {
      console.error(error);
      alert(
        error.response?.data?.error ||
          "An error occurred while deleting the news"
      );
    }
  };

  const [Image, setImage] = useState("");
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");

  const AddNews = async () => {
    if (!Image) {
      return alert("Please select an image");
    }
    if (!Title) {
      return alert("Please enter a title");
    }
    if (!Description) {
      return alert("Please enter a description");
    }

    try {
      const formData = new FormData();
      formData.append("image", Image); // Make sure the name here matches the field in your backend
      formData.append("title", Title);
      formData.append("description", Description);

      const config = {
        url: "/addnewsandevent",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: formData,
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetNews();
        handleClose();
        setImage("");
        setTitle("");
        setDescription("");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const [latestnewsid, setlatestnewsid] = useState("");
  const [Description1, setDescription1] = useState("");
  const AddDescription = async () => {
    if (!Description1) {
      return alert("Please enter a description");
    }
    try {
      const config = {
        method: "put",
        url: "/adddescription",
        baseURL: "https://thekitu.com/api/admin", // Keep baseURL here
        headers: { "Content-Type": "application/json" }, // Corrected header
        data: {
          description1: Description1,
          id: latestnewsid?._id,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetNews();
        handleClose1();
        setDescription1(""); // Reset the description field
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const DeleteDescription = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news item?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }

    try {
      const res = await axios.put(
        `https://thekitu.com/api/admin/deletedescription/${id}/${latestnewsid?._id}`
      );

      if (res.status === 200) {
        alert(res.data.success);
        setlatestnewsid(res.data.updatedData);
        GetNews(); // Refresh the news list after successful deletion
      }
    } catch (error) {
      console.error(error);
      alert(
        error.response?.data?.error ||
          "An error occurred while deleting the news"
      );
    }
  };

  useEffect(() => {
    GetNews();
  }, []);

  const [expandedItems, setExpandedItems] = useState({}); // Track expanded items per row
  const maxWords = 150;

  const toggleReadMore = (id) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the expanded state for the clicked item using its unique id
    }));
  };

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div>
          <h3>Latest News & Events</h3>
        </div>
        <div>
          <Button variant="danger" onClick={handleShow}>
            Add
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table bordered>
          <thead className="tablecolor">
            <tr>
              <th>Sl.No</th>
              <th>Date</th>
              <th>Image</th>
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {NewData?.map((item, i) => {
              const description = item?.description || "";
              const words = description.split(" ");
              const isExpanded = expandedItems[item._id]; // Use item._id to track the expanded state
              const shouldShowReadMore = words.length > maxWords;
              return (
                <tr>
                  <td>{++i}</td>
                  <td>
                    <div style={{ width: "100px" }}>
                      {moment(item?.createdAt)?.format("DD-MM-YYYY")}
                    </div>
                  </td>
                  <td>
                    <img
                      src={`https://thekitu.com/News/${item?.image}`}
                      alt="newsimage"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </td>
                  <td>{item?.title} </td>
                  <td>
                    {/* {parse(`<div>${item?.description}</div>`)} */}

                    <div>
                      {isExpanded
                        ? parse(`<div>${description}</div>`) // Show full description
                        : parse(
                            `<div>${words
                              .slice(0, maxWords)
                              .join(" ")}...</div>`
                          )}{" "}
                      {/* Show truncated description */}
                    </div>

                    {shouldShowReadMore && (
                      <div style={{color:'red',fontWeight:"700",cursor:"pointer"}} onClick={() => toggleReadMore(item._id)}>
                        {isExpanded ? "Read Less" : "Read More..."}
                      </div>
                    )}
                    <br />
                    <p
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        handleShow2();
                        setlatestnewsid(item);
                      }}
                    >
                      more description...
                    </p>
                  </td>
                  {/* <td>block</td> */}
                  <td>
                    <div>
                      <Button
                        variant="danger"
                        onClick={() => {
                          handleShow1();
                          setlatestnewsid(item);
                        }}
                      >
                        Add Content
                      </Button>
                      <MdDelete
                        className="mt-4"
                        onClick={() => DeleteNews(item?._id)}
                        style={{
                          color: "red",
                          fontSize: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Latest News & Events </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <div className="col-sm-6">
                <Form.Label>
                  Image<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="enter title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="row">
              <Form.Label>
                Description<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <CKEditor
                data={Description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                editor={ClassicEditor}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddNews}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Description Centent */}

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Form.Label>
              Description<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <CKEditor
              data={Description1}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription1(data);
              }}
              editor={ClassicEditor}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={AddDescription}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View Description Content */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <thead className="tablecolor">
              <tr>
                <th>Sl No.</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {latestnewsid?.content?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td> {parse(`<div>${item?.description1}</div>`)}</td>
                    <td>
                      {" "}
                      <MdDelete
                        className="mt-4"
                        onClick={() => DeleteDescription(item?._id)}
                        style={{
                          color: "red",
                          fontSize: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LatestNews;
