import React, { useEffect, useState } from "react";
import "../styles/Contact.css";
import axios from "axios";
import { Button } from "react-bootstrap";
const Contact = () => {
  const [Name, setName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");

  const AddCantact = async () => {
    if (!Name) {
      return alert("Please enater name");
    }
    if (!Mobile) {
      return alert("Please enter mobile no");
    }
    if (!Email) {
      return alert("Please enter a email");
    }
    if (!Message) {
      return alert("Please enter a Message");
    }

    try {
  
      const config = {
        url: "/addContactus",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: {
          name:Name,
          mobile:Mobile,
          email:Email,
          message:Message,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        setName("")
        setMobile("")
        setEmail("")
        setMessage("")    
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const [NewData, setNewData] = useState([]);
  const GetAddressDetails = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getaddressdetails`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAddressDetails()
  }, [])

  
  
  return (
    <div className="contact-us">
      <div className="gallery">
        {" "}
        <h1 style={{borderBottom:"3px solid white"}}>Contact Us</h1>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-6">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="company-address">
                  <h3>KITU Headquarters</h3>
                  <p>
                    {/* 2nd Floor, Brindavan Complex, 1st E Cross Road, 20th Main
                    Rd,BTM 1st Stage, Bengaluru,Karnataka 560068 */}
                    {NewData?.address}
                  </p>
                  <p>
                    <b>Mobile :</b> +91 {NewData?.phoneno}
                  </p>
                  <p>
                    <b>Email :</b> <a href=""> {NewData?.email}</a>
                  </p>
                  <div className="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345090373!2d144.9630576153168!3d-37.814107979751664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577ba1a5733b873!2sMelbourne%20Central!5e0!3m2!1sen!2sau!4v1600926314841!5m2!1sen!2sau"
                      width="100%"
                      height="250"
                      frameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="message-us">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <form className="-us">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your name"
                        onChange={(e)=>setName(e.target.value)}
                        value={Name}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="mobile"
                        placeholder="Enter your mobile number"
                        onChange={(e)=>setMobile(e.target.value)}
                        value={Mobile}
                        maxLength={10}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        onChange={(e)=>setEmail(e.target.value)}
                        value={Email}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="3"
                        placeholder="Enter your message"
                        onChange={(e)=>setMessage(e.target.value)}
                        value={Message}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-center">
                    <Button onClick={AddCantact} className="butt">
                      Send
                    </Button>
                    </div>
                 
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
