import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/NavigationBar";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import OurEvent from "./components/OurEvent";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import DetailsNews from "./components/DetailsNews";
import AdminHeader from "./components/Admin/AdminHeader";
import DashBoard from "./components/Admin/DashBoard";
import Menu from "./components/Menu";
import CommunityOutReach from "./components/CommunityOutReach";
import StudyandResearchcenter from "./components/StudyandResearchcenter";
import AdminLogin from "./components/Admin/AdminLogin";
import LatestNews from "./components/Admin/LatestNews";
import MostPopularNews from "./components/Admin/MostPopularNews";
import CommunityOutreach from "./components/Admin/CommunityOutreach";
import StudyResearchCenter from "./components/Admin/StudyResearchCenter";
import Campaign from "./components/Admin/Campaign";
import InNews from "./components/Admin/InNews";
import GalleryAdmin from "./components/Admin/GalleryAdmin";
import AboutusAdmin from "./components/Admin/AboutusAdmin";
import ContactUsAdmin from "./components/Admin/ContactUsAdmin";
import MostpopulerDetails from "./components/MostpopulerDetails";
import CommunityOutreachDetails from "./components/CommunityOutreachDetails";
import StudyandResearchDetails from "./components/StudyandResearchDetails";
import CampaignDetails from "./components/CampaignDetails";
import MembershipList from "./components/Admin/MembershipList";
import Whatsapp from "./components/Whatsapp";
import SocialMedialLinks from "./components/Admin/SocialMedialLinks";
import ContactDetails from "./components/Admin/ContactDetails";
import Termsandconditions from "./components/Termsandconditions";
import Privacypolicy from "./components/Privacypolicy";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          
          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <Home />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <About />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/about"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <OurEvent />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/event"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <CommunityOutReach />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/communityoutreach"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <StudyandResearchcenter />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/studyandresearchcenter"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <Gallery />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/gallery"
          />

          <Route
            element={
              <>
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <Contact />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/contact"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <DetailsNews />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/detailsnews"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <MostpopulerDetails />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/mostpopulerdetails"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <CommunityOutreachDetails />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/communityoutreachdetails"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <StudyandResearchDetails />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/studyandresearchdetails"
          />

          <Route
            element={
              <>
                {" "}
                {/* <Header /> */}
                <Navbar />
                <Menu />
                <CampaignDetails />
                <Whatsapp />
                <Footer />
              </>
            }
            path="/campaigndetails"
          />

        <Route
            element={
              <>
                <Termsandconditions/>
              </>
            }
            path="/termsandconditions"
          />

        <Route
            element={
              <>
                <Privacypolicy/>
              </>
            }
            path="/privacypolicy"
          />

          <Route exact path="/admin" element={<AdminLogin />} />

          <Route
            exact
            path="/dashboard"
            element={<AdminHeader children={<DashBoard />} />}
          />

          <Route
            exact
            path="/adminlatestnews"
            element={<AdminHeader children={<LatestNews />} />}
          />

          <Route
            exact
            path="/mostpopularnews"
            element={<AdminHeader children={<MostPopularNews />} />}
          />

          <Route
            exact
            path="/admincommunityoutreach"
            element={<AdminHeader children={<CommunityOutreach />} />}
          />

          <Route
            exact
            path="/adminstudyresearchcenter"
            element={<AdminHeader children={<StudyResearchCenter />} />}
          />

          <Route
            exact
            path="/campaign"
            element={<AdminHeader children={<Campaign />} />}
          />

          <Route
            exact
            path="/innews"
            element={<AdminHeader children={<InNews />} />}
          />

          <Route
            exact
            path="/galleryadmin"
            element={<AdminHeader children={<GalleryAdmin />} />}
          />

          <Route
            exact
            path="/aboutusadmin"
            element={<AdminHeader children={<AboutusAdmin />} />}
          />

          <Route
            exact
            path="/admincontactus"
            element={<AdminHeader children={<ContactUsAdmin />} />}
          />

          <Route
            exact
            path="/membershiplist"
            element={<AdminHeader children={<MembershipList />} />}
          />

          <Route
            exact
            path="/socialmediallinks"
            element={<AdminHeader children={<SocialMedialLinks />} />}
          />

          <Route
            exact
            path="/contactdetails"
            element={<AdminHeader children={<ContactDetails />} />}
          />

        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
