import React, { useState } from "react";
import "../styles/Modal.css";
import axios from "axios";
import { Button } from "react-bootstrap";

const Modal = ({ show, onClose }) => {
  const [Name, setName] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Gender, setGender] = useState("");
  const [Company, setCompany] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Address, setAddress] = useState("");

  const Membership = async () => {
    if(!Name){
      return alert("Enter Name")
    }
    if(!MobileNo){
      return alert("Enter Mobile No")
    }
    if(!Gender){
      return alert("Select gender")
    }
    if(!Company){
      return alert("Enter Company Name")
    }
    if(!Designation){
      return alert("Enter Designation")
    }
    if(!Address){
      return alert("Enter Address")
    }
    try {
      const config = {
        method: "post",
        url: "/addmembership",
        baseURL: "https://thekitu.com/api/user", // Keep baseURL here
        headers: { "Content-Type": "application/json" }, // Corrected header
        data: {
          name: Name,
          mobileno: MobileNo,
          gender: Gender,
          company: Company,
          designation: Designation,
          address: Address,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        onClose()
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  if (!show) {
    return null;
  }
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{ width: "530px", backgroundColor: "white" }}
      >
        <div className="modal-header">
          <h4>Membership Form</h4>

          <button className="close-button" onClick={onClose}>
            <i
              class="fas fa-times"
              style={{ color: "red", fontSize: "24px" }}
            />
          </button>
        </div>
        <h5 style={{ color: "black" }}>(Registration Charges 600/-)</h5>
        <div className="modal-body">
          <form>
            <label>
              Name{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setName(e.target.value)}
              value={Name}
              placeholder="Enter your name.."
              type="text"
              required
            />

            <label>
              Contact Number{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setMobileNo(e.target.value)}
              type="text"
              maxLength={10}
              placeholder="Enter your number"
              required
            />

            <label>
              Gender{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <select onChange={(e) => setGender(e.target.value)} required>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>

            <label>
              Company{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input 
            onChange={(e)=>setCompany(e.target.value)}
            value={Company}
            type="text"
             required 
             placeholder="Enter your company name" />

            <label>
              Designation{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input 
            onChange={(e)=>setDesignation(e.target.value)}
            type="text" 
            required 
            placeholder="Enter your designation" />

            <label>
              Residential Address{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <textarea 
            onChange={(e)=>setAddress(e.target.value)}
            required 
            placeholder="Enter your full address"></textarea>
            <Button variant="danger" onClick={Membership}>Submit</Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
