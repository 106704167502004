import React from "react";
import "../styles/Detailsnews.css";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
const DetailsNews = () => {
  const location = useLocation();
  const { item } = location.state || {};

  console.log("item", item);

  return (
    <div>
      <div className="details-news">
        <h1>Latest News & Events</h1>
      </div>
      <div className="container">
        <div className="details-news-img">
          <img src={`https://thekitu.com/News/${item?.image}`} alt="img" />
          <div className="details-news-content">
            <h3>{item?.title} </h3>

            <p>{parse(`<div>${item?.description}</div>`)}</p>

            {item?.content?.map((item1) => {
              return <p>{parse(`<div>${item1?.description1}</div>`)}</p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsNews;
