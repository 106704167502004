import React, { useState } from "react";
import "./Css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container,Form, Button, Card } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
function AdminLogin() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
const [Email, setEmail] = useState("")
const [Password, setPassword] = useState("")
  const adminlogin = async ()=>{
    try {
      const config = {
        url:"/adminlogin",
        method:"post",
        baseURL:"https://thekitu.com/api/admin",
        headers:{"content-type":"application/json"},
        data:{
          email:Email,
          password:Password
        }
      }
      let res = await axios(config)
      if(res.status === 200){
        alert(res.data.msg)
        sessionStorage.setItem('admin', JSON.stringify(res.data.admindetails));
        window.location.assign("/dashboard");
      }
    } catch (error) {
      alert(error.response.data.msg)
    }
  }


  return (
    <Container
      fluid
      className="login-container d-flex justify-content-center align-items-center"
    >
      <CSSTransition in={true} appear={true} timeout={500} classNames="fade">
        <Card className="login-card shadow-lg">
          <Card.Body>
            <Card.Title className="text-center mb-4">
              KARNATAKA STATE IT/ITeS EMPLOYEES UNION
            </Card.Title>
            <h5 className="text-center text-muted mb-4">Login Page</h5>
            <Form>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  required
                  onChange={(e)=>setEmail(e.target.value)}
                  value={Email}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <div className="password-container">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    required
                    onChange={(e)=>setPassword(e.target.value)}
                    value={Password}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={togglePasswordVisibility}
                    className="toggle-password"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </div>
              </Form.Group>
             
                <Button onClick={adminlogin} variant="primary" className="w-100">
                  Login
                </Button>
             
            </Form>
          </Card.Body>
        </Card>
      </CSSTransition>
    </Container>
  );
}

export default AdminLogin;
