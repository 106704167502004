import React from 'react'

const Privacypolicy = () => {
  return (
    <div className='container'>

        <div className='fs-1 mt-2 mb-3'><b><u>Privacy Policy :</u></b></div>

        <div className='d-flex gap-2 align-items-center'>
            <div className='fs-3'><b>Effective Date:</b></div> <span className='pt-1'>14-10-2024</span>
        </div>
        <p>
        Karnataka State IT/ ITES Employees Union (referred to as "we," "us," or "our") is committed to protecting the privacy of our members and website visitors. This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
        By using our website and registering as a member, you agree to the terms outlined in this privacy policy. If you do not agree with the terms, please do not use our services.
        </p>

        <div className='fs-3'><b>Information We Collect</b></div>
        <p>We collect personal and non-personal information to process your membership registration, payments, and enhance your experience with our union.</p>

            <div className='fs-4'><b>Personal Information</b></div>
            <p>We may collect the following personal information from you when you register for membership or use our services:</p>
            <ul>
                <li>Full Name</li>
                <li>Email Address</li>
                <li>Postal Address</li>
                <li>Phone Number</li>
                <li>Date of Birth</li>
                <li>Employment Details (e.g., Job Title, Company Name)</li>
                <li>Payment Information (e.g., credit card details)</li>
            </ul>


            <div className='fs-4'><b>Non-Personal Information</b></div>
            <p>We may collect non-personal information such as:</p>
            <ul>
                <li>IP Address</li>
                <li>Browser Type</li>
                <li>Device Type</li>
                <li>Operating System</li>
                <li>Pages Visited on our Website</li>
                <li>Time and Date of Visit</li>
            </ul>

            <div className='fs-3'><b>How We Use Your Information</b></div>
            <p>We use your information to provide, maintain, and improve our services. Specifically, we may use your information for the following purposes:</p>
            <ul>
                <li>To process and manage membership registrations and renewals.</li>
                <li>To process payments securely.</li>
                <li>To communicate with you regarding your membership, including renewal notices, updates, and announcements.</li>
                <li>To send newsletters, promotional content, or event invitations (you can opt-out of these communications at any time).</li>
                <li>To improve our website and services based on usage patterns.</li>
                <li>To comply with legal obligations and resolve disputes.</li>
            </ul>

            <div className='fs-3'><b>How We Protect Your Information</b></div>
            <p>We use industry-standard security measures to protect your personal and payment information during transmission and storage. These measures include:</p>
            <ul>
                <li>SSL Encryption: All personal and payment information entered on our site is encrypted using secure socket layer (SSL) technology.</li>
                <li>Data Access Control: Access to personal information is restricted to authorized personnel only.</li>
                <li>Regular Security Audits: We periodically review our data security practices to ensure ongoing protection against unauthorized access.</li>
            </ul>
            <p>However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
    
            <div className='fs-3'><b>Sharing Your Information</b></div>
            <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information under the following circumstances:</p>
            <li>Service Providers :
                We may share your information with trusted third-party service providers who assist us in operating the website, processing payments, or delivering communications. These providers are obligated to maintain the confidentiality of your information.
            </li>
            <li>Legal Compliance :
            We may disclose your personal information if required to do so by law or in response to valid requests from public authorities (e.g., a court or government agency).
            </li>
            <li>Business Transfers :
            In the event of a merger, acquisition, or asset sale, your personal information may be transferred as part of the transaction.
            </li>

            <div className='fs-3'><b>Your Data Protection Rights</b></div>
            <p>Depending on your location, you may have the following rights under applicable data protection laws:</p>
            <ul>
                <li>Access: You have the right to request access to the personal information we hold about you.</li>
                <li>Rectification: You have the right to request that we correct any inaccurate or incomplete information.</li>
                <li>Deletion: You have the right to request that we delete your personal information, subject to certain conditions.</li>
                <li>Objection: You have the right to object to the processing of your personal information under certain circumstances.</li>
                <li>Data Portability: You have the right to request that your personal information be transferred to another service provider in a machine-readable format.</li>
            </ul>
            <p>To exercise any of these rights, please contact us at kituhq@gmail.com</p>

            <div className='fs-3'><b>Cookies and Tracking Technologies</b></div>
            <p>Our website uses cookies and similar tracking technologies to enhance your experience and gather information about how the website is used. Cookies are small data files stored on your device that help us recognize you when you visit.</p>
  
            <div className='fs-4'><b>Types of Cookies We Use:</b></div>
            <ul>
                <li>Necessary Cookies: These cookies are essential for website functionality.</li>
                <li>Analytics Cookies: These cookies collect information about how visitors use our site, helping us improve its performance.</li>
                <li>Preference Cookies: These cookies remember your preferences and settings.
                    You can modify your browser settings to block cookies or alert you when cookies are being sent. However, some parts of the website may not function properly without cookies.
                </li>
            </ul>

            <div className='fs-3'><b>Third-Party Links</b></div>
            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

            <div className='fs-3'><b>Data Retention</b></div>
            <p>We will retain your personal information for as long as your membership is active or as necessary to provide you with our services. We may also retain your information to comply with legal obligations, resolve disputes, or enforce our agreements.</p>

            <div className='fs-3'><b>Changes to This Privacy Policy</b></div>
            <p>We may update this privacy policy from time to time. Any changes will be posted on this page, along with the effective date. Your continued use of our website after any changes indicates your acceptance of the updated policy.</p>

            <div className='fs-3'><b>Contact Us</b></div>
            <p>If you have any questions about this privacy policy or our data practices, please contact us at:</p>
            <ul>
                <li>Email: kituhq@gmail.com</li>
                <li>Phone: +91 7019781243</li>
                <li>Address: Suri Bhavan, 40/5, 16th Cross 2B Main, Sampangiramanagara, Bengaluru - 560027</li>
            </ul>
    </div>
  )
}

export default Privacypolicy