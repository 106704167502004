import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";


function ContactUsAdmin() {

  
    const [NewData, setNewData] = useState([]);
    const GetContactus = async () => {
      try {
        const getdata = await axios.get(
          `https://thekitu.com/api/admin/getContactus`
        );
        if (getdata.status === 200) {
          setNewData(getdata.data.news);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const DeleteContactUs = async (id) => {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this news item?"
      );
  
      if (!confirmDelete) {
        return; // Exit if the user cancels the action
      }
  
      try {
        const res = await axios.delete(
          `https://thekitu.com/api/admin/deleteContactus/${id}`
        );
  
        if (res.status === 200) {
          alert(res.data.success);
          GetContactus(); // Refresh the news list after successful deletion
        }
      } catch (error) {
        console.error(error);
        alert(
          error.response?.data?.error ||
            "An error occurred while deleting the news"
        );
      }
    };
  

  
    useEffect(() => {
        GetContactus();
    }, []);
  return (
    <div>
    <div className="d-flex justify-content-between mt-4">
      <div>
        <h3>Contact Us List</h3>
      </div>
      <div>
  
      </div>
    </div>
    <div className="mt-3">
      <Table bordered>
        <thead className="tablecolor">
          <tr>
            <th>Sl.No</th>
            <th>Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile No</th>
            <th>Meaages</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {NewData?.map((item, i) => {
            return (
              <tr>
                <td>{++i}</td>
                <td>
                  <div style={{ width: "100px" }}>
                    {moment(item?.createdAt)?.format("DD-MM-YYYY")}
                  </div>
                </td>
                <td>
                  {item?.name}
                </td>
                <td>{item?.email}</td>
                <td>  {item?.mobile} </td>
              
                <td>{item?.message}</td>
                <td>
                  <MdDelete
                    onClick={() => DeleteContactUs(item?._id)}
                    style={{
                      color: "red",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>


  </div>
  )
}

export default ContactUsAdmin