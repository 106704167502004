import React, { useEffect, useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
import Carousel from "react-grid-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Home.css";
import LatestNews from "./LatestNews";
import MostPopular from "./MostPopular";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
const Home = () => {
  const navigate = new useNavigate();
  const [NewData, setNewData] = useState([]);
  const GetStudyResearchCenter = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getstudyresearchcenter`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [GalleryImg, setGalleryImg] = useState([]);
  const GetGalleryImages = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getGalleryImages`
      );
      if (getdata.status === 200) {
        setGalleryImg(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetStudyResearchCenter();
    GetGalleryImages();
  }, []);

  return (
    <div style={{ padding: "0 12px" }}>
      <section id="section2">
        <LatestNews />
      </section>

      <section id="section3">
        <MostPopular />
      </section>

      <section id="section4">
        <div className="row">
          <div
            className="col-md-9 p-5 "
            style={{ position: "relative", marginTop: "-50px" }}
          >
            <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
              <h3>Study & Research Center</h3>
            </div>
            <div className="row nnn">
              <Carousel cols={4} rows={1} gap={10} loop>
                {NewData?.map((item) => {
                  return (
                    <Carousel.Item>
                      <div className="">
                        <div class="card-container card-imagition">
                          <div class="card my-card">
                            <img
                              src={`https://thekitu.com/StudyResearch/${item?.image}`}
                              alt="Image1"
                              class="card-image"
                            />
                            <div class="card-content">
                              <a
                                href="/studyandresearchdetails"
                                onClick={() =>
                                  navigate("/studyandresearchdetails", {
                                    state: { item },
                                  })
                                }
                              >
                                {" "}
                                <h5>
                                  {item?.title?.length > 60
                                    ? item?.title.slice(0, 60) + "..."
                                    : item?.title}
                                </h5>
                              </a>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.description?.length > 80
                                      ? item?.description.slice(0, 80) + "..."
                                      : item?.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
                {/* <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item> */}
              </Carousel>
            </div>

            {/* <div className="row justify-content-center">
              <div className="col-md-8">
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 eventimage"
                      src="/Assets/image2.jpg"
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 eventimage"
                      src="/Assets/image3.jpg"
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 eventimage"
                      src="/Assets/image4.jpg "
                      alt="Fourth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 eventimage"
                      src="/Assets/image5.jpg"
                      alt="Fifth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 eventimage"
                      src="/Assets/image6.jpg"
                      alt="Sixth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 eventimage"
                      src="/Assets/image7.jpg"
                      alt="Seventh slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 eventimage"
                      src="/Assets/image8.jpg"
                      alt="Eighth slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div> */}
          </div>

          <div className="col-md-3" style={{ marginTop: "-50px" }}>
            <div className="lastestnews-updates">
              <h3>Gallery</h3>
            </div>

            <div className="row justify-content-center bbbb mt-3">
              <Carousel>
                {GalleryImg?.map((item) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 eventimage"
                        src={`https://thekitu.com/Gallery/${item?.image}`}
                        alt="Second slide"
                      />
                    </Carousel.Item>
                  );
                })}
                {/* <Carousel.Item>
                  <img
                    className="d-block w-100 eventimage"
                    src="/Assets/image2.jpg"
                    alt="Second slide"
                  />
                </Carousel.Item> */}
                {/* <Carousel.Item>
                  <img
                    className="d-block w-100 eventimage"
                    src="/Assets/image3.jpg"
                    alt="Third slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 eventimage"
                    src="/Assets/image4.jpg "
                    alt="Fourth slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 eventimage"
                    src="/Assets/image5.jpg"
                    alt="Fifth slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 eventimage"
                    src="/Assets/image6.jpg"
                    alt="Sixth slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 eventimage"
                    src="/Assets/image7.jpg"
                    alt="Seventh slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 eventimage"
                    src="/Assets/image8.jpg"
                    alt="Eighth slide"
                  />
                </Carousel.Item> */}
              </Carousel>
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-md-11">
                <h3 className="latestnewsxxx">Check Youtube</h3>
                <div className="all-youtube-link">
                  <a href="www.youtube.com">
                    <div className="video-link">
                      <i
                        className="fab fa-youtube"
                        style={{ color: "red", fontSize: "18px" }}
                      ></i>
                      <p>
                        https://www.youtube.com/watch?v=5ic4_KSggdsffddfdffd
                      </p>
                    </div>
                  </a>

                  <a href="www.youtube.com">
                    <div className="video-link">
                      <i
                        className="fab fa-youtube"
                        style={{ color: "red", fontSize: "18px" }}
                      ></i>
                      <p>https://www.youtube.com/watch?v=5ic465_KSgg</p>
                    </div>
                  </a>
                  <a href="www.youtube.com">
                    <div className="video-link">
                      <i
                        className="fab fa-youtube"
                        style={{ color: "red", fontSize: "18px" }}
                      ></i>
                      <p>https://www.youtube.com/watch?v=5ic465_KSgg</p>
                    </div>
                  </a>
                  <a href="www.youtube.com">
                    <div className="video-link">
                      <i
                        className="fab fa-youtube"
                        style={{ color: "red", fontSize: "18px" }}
                      ></i>
                      <p>https://www.youtube.com/watch?v=5ic465_KSgg</p>
                    </div>
                  </a>
                  <a href="www.youtube.com">
                    <div className="video-link">
                      <i
                        className="fab fa-youtube"
                        style={{ color: "red", fontSize: "18px" }}
                      ></i>
                      <p>https://www.youtube.com/watch?v=5ic465_KSgg</p>
                    </div>
                  </a>
                </div>
              </div>
            </div> */}

            <div className="mt-5 d-flex justify-content-center">
              <Button variant="danger" onClick={()=>navigate("/gallery")} > View More</Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
