import React from "react";
import "../styles/Detailsnews.css";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
function MostpopulerDetails() {
  const location = useLocation();
  const { item } = location.state || {};

  return (
    <div>
      <div className="details-news">
        <h1>Most Popular News Details</h1>
      </div>
      <div className="container">
        <div className="details-news-img">
          <img
            src={`https://thekitu.com/MostPopuler/${item?.image}`}
            alt="images"
          />
          <div className="details-news-content">
            <h3>{item?.title} </h3>

            <p>
              <p>{parse(`<div>${item?.description}</div>`)}</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MostpopulerDetails;
