import React, { useEffect, useState } from "react";
import "../styles/Gallery.css";
import axios from "axios";
const Gallery = () => {
  const [NewData, setNewData] = useState([]);
  const GetGalleryImages = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getGalleryImages`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetGalleryImages();
    window.scrollTo(0,0)
  }, []);



  return (
    <div>
      <section>
        <div className="gallery">
          {" "}
          <h1 style={{borderBottom:"3px solid white"}}>Gallery</h1>
        </div>
        <div className="container">
          <div className="row">
            {NewData?.map((item) => {
              return (
                <div className="col-md-4 p-3  ">
                  <img
                     src={`https://thekitu.com/Gallery/${item?.image}`}
                    alt=""
                    className="galleryimg"
                  />
                </div>
              );
            })}

            {/* <div className="col-md-4 p-3 ">
              <img src="/Assets/image2.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3">
              <img src="/Assets/image3.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3 ">
              <img src="/Assets/image4.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3 ">
              <img src="/Assets/image5.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3 ">
              <img src="/Assets/image6.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3 ">
              <img src="/Assets/image7.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3 ">
              <img src="/Assets/image8.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3  ">
              <img src="/Assets/image1.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3  ">
              <img src="/Assets/image10.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3  ">
              <img src="/Assets/update1.jpg" alt="" className='galleryimg'/>
          </div>
          <div className="col-md-4 p-3  ">
              <img src="/Assets/kitulogo.png" alt="" className='galleryimg'/>
          </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
