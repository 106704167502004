import React from 'react'

const Termsandconditions = () => {
    
  return (

    <div className='container'>
        
        <div className='fs-1 mt-2 mb-3'><b><u>Terms and Conditions :</u></b></div>

        <div className='fs-3'><b>Introduction</b></div>
        <p>Welcome to the Karnataka State IT/ ITES Employees Union website. By using our site, you agree to be bound by the following terms and conditions. Please read them carefully before registering for membership or making any payment.</p>

        <div className='fs-3'><b>Membership Eligibility</b></div>
        <p>Any employee belonging to the Information Technology/Information Technology Enabled Services or Computer/Software-aided work in the State of Karnataka, working directly as employees or through a contractor on work based contract or labour supply or in any other form shall be entitled to become a member of the union. Applicants must be at least 18 years old and agree to comply with the union's regulations and code of conduct.</p>

        <div className='fs-3'><b>Registration and Account</b></div>
        <ul>
            <li>When registering for membership, you agree to provide accurate and up-to-date information.</li>
            <li>You are responsible for maintaining the confidentiality of your account and password.</li>
            <li>We reserve the right to terminate or suspend your membership if you breach any of these terms.</li>
        </ul>

        <div className='fs-3'><b>Membership Fees and Payment</b></div>
        <ul>
            <li>All membership fees are clearly stated on the membership registration page.</li>
            <li>Payments are accepted online through secure payment gateways. By submitting payment, you authorize us to charge the applicable fees to your chosen payment method.</li>
            <li>Membership fees are subject to change, and we will provide notice of any fee changes on the website.</li>
            <li>All payments are final, and refunds are not available under any circumstances.</li>
        </ul>

        <div className='fs-3'><b>Membership Renewal and Cancellation</b></div>
        <ul>
            <li>Membership is valid for a specified period, usually one year, unless otherwise stated.</li>
            <li>You will receive reminders to renew your membership before it expires. Failure to renew will result in suspension of membership benefits.</li>
            <li>You may cancel your membership at any time by contacting Karnataka State IT/ITeS Employees Union in writing. Cancellation does not entitle you to a refund for the current membership period.</li>
        </ul>

        <div className='fs-3'><b>Code of Conduct</b></div>
        <ul>
            <li>All members are expected to abide by the union’s code of conduct, which promotes professionalism, integrity, and ethical practices within the IT community.</li>
            <li>Any violation of the code of conduct may result in termination of membership without refund.</li>
        </ul>

        <div className='fs-3'><b>Privacy Policy</b></div>
        <li>We value your privacy and are committed to protecting your personal information.</li>
        <li>Any personal information provided during the registration process or through the use of the website will be handled in accordance with our Privacy Policy.</li>

        <div className='fs-3'><b>Security</b></div>
        <p>We take reasonable steps to protect your payment and personal information during transmission using secure socket layer (SSL) encryption. However, we cannot guarantee absolute security, and by using the site, you acknowledge that you understand the risks.</p>

        <div className='fs-3'><b>Limitation of Liability</b></div>
       <ul>
        <li>Karnataka State IT/ ITES Employees Union will not be liable for any indirect, incidental, or consequential damages arising from the use of this site, including, but not limited to, loss of profits or data.</li>
        <li>We are not responsible for any unauthorized access to or alteration of your transmissions or data.</li>
       </ul>

        <div className='fs-3'><b>Modification of Terms</b></div>
        <p>We reserve the right to modify these terms and conditions at any time. Any changes will be posted on this page, and your continued use of the website or membership services constitutes your acceptance of any new terms.</p>

        <div className='fs-3'><b>Governing Law</b></div>
        <p>These terms and conditions shall be governed by and construed in accordance with the laws of Karnataka, without regard to its conflict of law principles.</p>

        <div className='fs-3'><b>Contact Information</b></div>
        <p>For any questions or concerns about these terms, please contact us at:</p>
        <ul>
            <li>Email: kituhq@gmail.com</li>
            <li>Phone: +91 7019781243</li>
            <li>Address: Suri Bhavan, 40/5, 16th Cross 2B Main, Sampangiramanagara, Bengaluru - 560027</li>
        </ul>

    </div>
  )
}

export default Termsandconditions
