import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function InNews() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [NewData, setNewData] = useState([]);
    const GetInlinks = async () => {
      try {
        const getdata = await axios.get(
          `https://thekitu.com/api/admin/getInlinks`
        );
        if (getdata.status === 200) {
          setNewData(getdata.data.news);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const DeleteInlinks = async (id) => {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this news item?"
      );
  
      if (!confirmDelete) {
        return; // Exit if the user cancels the action
      }
  
      try {
        const res = await axios.delete(
          `https://thekitu.com/api/admin/deleteInlinks/${id}`
        );
  
        if (res.status === 200) {
          alert(res.data.success);
          GetInlinks(); // Refresh the news list after successful deletion
        }
      } catch (error) {
        console.error(error);
        alert(
          error.response?.data?.error ||
            "An error occurred while deleting the news"
        );
      }
    };
  
    const [Image, setImage] = useState("");
    const [Links, setLinks] = useState("");
    const [Description, setDescription] = useState("");
  
    const AddInLinks = async () => {
      if (!Image) {
        return alert("Please select an image");
      }
      if (!Links) {
        return alert("Please past link");
      }
    //   if (!Description) {
    //     return alert("Please enter a description");
    //   }
  
      try {
        const formData = new FormData();
        formData.append("image", Image); // Make sure the name here matches the field in your backend
        formData.append("links", Links);
        // formData.append("description", Description);
  
        const config = {
          url: "/addInlinks",
          method: "post",
          baseURL: "https://thekitu.com/api/admin",
          data: formData,
        };
  
        let res = await axios(config);
        if (res.status === 200) {
          alert(res.data.success);
          GetInlinks();
          handleClose();
          setImage("");
          setLinks("");
        }
      } catch (error) {
        alert(error.response?.data?.error || "An error occurred");
      }
    };
  
    useEffect(() => {
        GetInlinks();
    }, []);
  return (
    <div>
    <div className="d-flex justify-content-between mt-4">
      <div>
        <h3>In News </h3>
      </div>
      <div>
        <Button variant="danger" onClick={handleShow}>
          Add
        </Button>
      </div>
    </div>
    <div className="mt-3">
      <Table bordered>
        <thead className="tablecolor">
          <tr>
            <th>Sl.No</th>
            <th>Date</th>
            <th>Image</th>
            <th>Links</th>
            {/* <th>Description</th> */}
            {/* <th>Status</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {NewData?.map((item, i) => {
            return (
              <tr>
                <td>{++i}</td>
                <td>
                  <div style={{ width: "100px" }}>
                    {moment(item?.createdAt)?.format("DD-MM-YYYY")}
                  </div>
                </td>
                <td>
                  <img
                    src={`https://thekitu.com/InLinks/${item?.image}`}
                    alt="newsimage"
                    style={{ width: "150px", height: "150px" }}
                  />
                </td>
                <td>{item?.links} </td>
                {/* <td>{parse(`<div>${item?.description}</div>`)}</td> */}
                {/* <td>block</td> */}
                <td>
                  <MdDelete
                    onClick={() => DeleteInlinks(item?._id)}
                    style={{
                      color: "red",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>

    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add In News</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="row">
            <div className="col-sm-6">
              <Form.Label>
                Image<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <Form.Label>
              Link<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="paste link"
              onChange={(e) => setLinks(e.target.value)}
            />
          </div>
          {/* <div className="row">
            <Form.Label>
              Description<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <CKEditor
              data={Description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
              editor={ClassicEditor}
            />
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={AddInLinks}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  )
}

export default InNews